import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const SubHeader = ({ title, subtitle1, subtitle2 }) => {
  const headingRef = useRef(null);
  const subtitleRef1 = useRef(null);
  const subtitleRef2 = useRef(null);

  useEffect(() => {
    anime({
      targets: headingRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 1000,
      duration: 1000,
      easing: 'easeOutExpo',
    });

    anime({
      targets: subtitleRef1.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 1500,
      duration: 1000,
      easing: 'easeOutExpo',
    });

    anime({
      targets: subtitleRef2.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 2000,
      duration: 1000,
      easing: 'easeOutExpo',
    });
  }, []);

  return (
    <div className='subheader'>
      <h1 className='bottomvideop' h1 ref={headingRef} dangerouslySetInnerHTML={{ __html: title }} />
      <div className='contenedortextsubheader'>
        <p ref={subtitleRef1} dangerouslySetInnerHTML={{ __html: subtitle1 }}></p>
        <p ref={subtitleRef2} dangerouslySetInnerHTML={{ __html: subtitle2 }}></p>
      </div>
    </div>
  );
};

export default SubHeader;
