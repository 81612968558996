import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const BeneficiosContainer = ({ benefits }) => {
  const benefitsRef = useRef(null);

  useEffect(() => {
    // Animación de la sección
    anime({
      targets: benefitsRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 3000,
      duration: 1000,
      easing: 'easeOutExpo',
    });

    // Animación del gradiente con movimiento horizontal continuo
    const animateGradient = () => {
      anime({
        targets: '#gradient',
        x1: ['0%', '100%'],
        x2: ['100%', '200%'],
        easing: 'linear',
        duration: 5000,
        loop: true,
        direction: 'alternate'
      });
    };
    
    animateGradient();
  }, []);

  return (
    <section className='benefits-section' ref={benefitsRef} style={{ background: 'none' }}>
      <div className='benefitcontainer'>
        {benefits.map((benefit, index) => (
          <div className='benefit' key={index}>
            <img src={benefit.icon} alt={`Icon ${index + 1}`} />
            <p><span dangerouslySetInnerHTML={{ __html: benefit.text }}></span></p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BeneficiosContainer;
