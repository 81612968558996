import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/logow.png';
import headertext from '../assets/images/headertext.png';


const Footer = () => {
  return (
    <div className="footer-container">
      <div className="icons-container">
        <a href="https://uy.linkedin.com/company/movistar-empresas-uruguay" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
        <a href="https://www.facebook.com/MovistarUruguay/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="icon" />
        </a>
        <a href="https://www.instagram.com/movistaruy" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
      </div>
      <div className="separator"></div>
      <div className="text-container">
        <img className='logoblancoempresas' src={logo} alt="Logo"/>
        <img className='evoluciondigital' src={headertext} alt="headertext"/>
      </div>
    </div>
  );
}


export default Footer;