import React from 'react';

const LogoSVG = () => (
  <svg className='logo' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1443.14 385.65">
    <defs>
      <style>
        {`.cls-1 {
          fill: #ffffff;
          stroke-width: 0px;
        }`}
      </style>
    </defs>
    <path className="cls-1" d="M105.93,54.47c-22.61.36-64.35,11.51-83.41,89.36-8.3,33.92-11.51,69.28-4.41,111.37,6.56,38.82,18.17,72.33,26,90.79,2.7,6.36,6.88,13,10.11,17.08,9.29,11.74,24.75,10.99,31.25,7.79,7.08-3.49,15.22-11.91,12.28-31.13-1.42-9.29-5.53-22.87-7.84-30.43-7.09-23.17-16.54-51.14-17.36-71.06-1.1-26.64,9.4-30.13,16.38-31.67,11.73-2.58,21.57,10.3,30.91,26.45,11.15,19.27,30.26,53.41,45.85,79.49,14.07,23.54,40.05,48.75,81.76,47.02,42.52-1.76,73.87-17.99,90.02-69.06,12.08-38.21,20.32-66.77,33.57-96.01,15.24-33.63,35.57-51.63,52.69-46.13,15.89,5.1,19.86,20.64,20.05,43.47.17,20.2-2.17,42.47-3.99,58.83-.66,5.94-1.85,17.88-1.37,24.51.96,13.03,6.6,26.05,21.28,28.14,15.64,2.22,28.18-10.28,33.19-25.38,1.98-5.96,3.66-15.07,4.57-21.53,4.59-32.64,5.79-54.58,3.72-87.98-2.43-39.05-10.07-74.67-23.4-105.49-12.75-29.47-33.24-48.36-59.52-50.03-29.09-1.85-62.46,17.45-79.98,54.89-16.14,34.51-29.05,69.94-36.89,88.02-7.95,18.34-19.62,29.64-37.57,31.53-21.95,2.31-40.86-13.62-54.71-36.34-12.08-19.8-36.01-57.51-48.81-70.18-12.03-11.9-25.77-26.79-54.38-26.34Z"/>
    <g>
      <path className="cls-1" d="M591.79,118.7h82.97l-1.69,14.82h-62.44v49.11h50.59v15.24h-50.59v50.8h65.41v14.82h-84.25V118.7Z"/>
      <path className="cls-1" d="M700.58,159.76h14.6l.85,7.62c10.8-6.77,22.01-10.58,33.44-10.58,13.12,0,22.22,3.81,28.15,11.64,12.91-7.83,25.61-11.64,37.25-11.64s20.11,2.96,26.04,9.1c5.71,5.72,8.68,14.18,8.68,27.09v70.49h-17.57v-68.37c0-8.89-1.48-13.97-5.08-17.57-3.6-3.6-9.1-5.29-16.3-5.29-8.47,0-18.84,2.54-27.94,6.98.85,3.81,1.48,8.47,1.48,13.76v70.49h-17.57v-68.37c0-8.89-1.48-13.97-5.08-17.57-3.6-3.6-9.1-5.29-16.3-5.29-8.89,0-18.63,2.75-27.09,6.77v84.46h-17.57v-103.72Z"/>
      <path className="cls-1" d="M877.96,159.76h14.61l.85,7.62c10.8-7.2,22.86-10.58,32.39-10.58,26.88,0,39.79,21.38,39.79,54.4,0,38.95-15.66,55.25-40.43,55.25-10.79,0-22.01-2.96-29.63-7.83v52.49h-17.57v-151.34ZM947.18,210.99c0-26.46-7.83-39.16-25.19-39.16-8.04,0-18.42,2.75-26.46,6.99v66.68c6.14,3.6,16.3,6.35,25.19,6.35,18.84,0,26.46-11.22,26.46-40.85Z"/>
      <path className="cls-1" d="M988.88,159.76h14.61l1.06,8.89c9.95-8.25,20.32-11.85,29.42-11.85,1.91,0,4.45.21,6.77.63v15.66c-2.33-.21-6.14-.42-9.1-.42-9.1,0-17.78,2.33-25.19,6.99v83.82h-17.57v-103.72Z"/>
      <path className="cls-1" d="M1062.33,253.96c-9.31-9.31-13.55-23.5-13.55-42.12,0-37.04,18.42-55.03,44.87-55.03,29,0,42.97,20.53,42.97,54.61v4.02c0,1.69-.85,2.33-2.54,2.33h-66.89c0,11.43,2.96,20.74,9.1,26.46,5.5,5.08,13.33,7.83,26.46,7.83,7.41,0,17.57-1.06,25.61-2.75l1.69,13.12c-8.89,2.54-21.17,4.02-30.69,4.02-16.09,0-28.58-4.02-37.04-12.49ZM1119.27,204.85c-.63-19.9-8.26-33.87-25.61-33.87s-26.25,12.91-26.46,33.87h52.07Z"/>
      <path className="cls-1" d="M1157.37,262l.85-13.55c8.47,2.33,19.9,3.81,29,3.81,16.93,0,26.04-5.71,26.04-16.3,0-23.28-57.79-11.22-57.79-49.32,0-20.11,15.66-29.85,37.68-29.85,10.37,0,22.01,1.91,30.27,4.45l-1.48,12.91c-9.31-2.12-18.84-3.39-27.31-3.39-14.82,0-22.65,4.87-22.65,14.6,0,23.28,57.79,12.28,57.79,49.32,0,22.01-16.93,31.75-41.91,31.75-9.95,0-22.65-1.9-30.48-4.44Z"/>
      <path className="cls-1" d="M1256.43,256.5c-5.08-5.29-8.25-12.7-8.25-22.44s3.39-17.36,8.89-22.44c7.83-7.2,20.11-9.95,35.14-9.95h21.59v-6.56c0-8.47-1.48-13.76-5.29-17.57-4.45-4.23-11.22-6.14-21.59-6.14-7.83,0-17.78.85-27.94,2.54l-.63-13.55c9.74-2.33,21.59-3.6,31.54-3.6,15.45,0,25.82,3.39,32.38,9.53,6.56,6.35,9.1,14.6,9.1,28.15v69h-14.18l-1.06-6.99c-9.1,5.93-20.11,9.95-32.6,9.95-11.22,0-20.74-3.39-27.09-9.95ZM1313.79,245.49v-30.9h-19.05c-10.79,0-18.2,1.48-23.07,5.29-3.6,2.96-5.71,7.41-5.71,13.76,0,12.49,7.83,18.63,21.38,18.63,8.47,0,18.84-2.96,26.46-6.77Z"/>
      <path className="cls-1" d="M1356.55,262l.85-13.55c8.47,2.33,19.9,3.81,29,3.81,16.93,0,26.04-5.71,26.04-16.3,0-23.28-57.79-11.22-57.79-49.32,0-20.11,15.66-29.85,37.68-29.85,10.37,0,22.01,1.91,30.27,4.45l-1.48,12.91c-9.31-2.12-18.84-3.39-27.31-3.39-14.82,0-22.65,4.87-22.65,14.6,0,23.28,57.79,12.28,57.79,49.32,0,22.01-16.93,31.75-41.91,31.75-9.95,0-22.65-1.9-30.48-4.44Z"/>
    </g>
  </svg>
);

export default LogoSVG;
