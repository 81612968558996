import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import fondo from '../assets/images/fondo_comunicacion.png';
import anime from 'animejs/lib/anime.es.js';

const Comunicacion = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    anime({
      targets: '.comunicacion-list button',
      translateX: [-300, 0], // Cambia translateY a translateX para que venga desde la izquierda
      opacity: [0, 1],
      delay: anime.stagger(300), // Aumenta el delay entre botones para que no se solapen
      easing: 'easeOutExpo',
      duration: 2400 // Aumenta la duración para que sea más lenta
    });

    anime({
      targets: '.image-fondo-container img',
      translateX: [300, 0], // Haz que la imagen también venga desde la derecha
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 3000 // Aumenta la duración para hacer la animación más lenta
    });

  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="comunicacion container">
      <div className="image-fondo-container">
        <img src={fondo} alt="Fondo"></img>
      </div>
      <div className="comunicacion-list">
        <button onClick={() => handleNavigation('/comunicacion-y-conectividad/sd-wan')}>SD-WAN Movistar</button>
        <button onClick={() => handleNavigation('/comunicacion-y-conectividad/pdti')}>PDTI</button>
        <button onClick={() => handleNavigation('/comunicacion-y-conectividad/movistar-link')}>Movistar Link</button>
      </div>
    </div>
  );
};

export default Comunicacion;
