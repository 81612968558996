import React, { useEffect } from 'react';
import $ from 'jquery';

const CustomCursor = () => {
  useEffect(() => {
    const cursor = $(".cursor");

    const handleMouseMove = (e) => {
      cursor.css({
        top: e.clientY - cursor.height() / 2,
        left: e.clientX - cursor.width() / 2,
      });
    };

    const handleMouseLeave = () => {
      cursor.css({ opacity: "0" });
    };

    const handleMouseEnter = () => {
      cursor.css({ opacity: "1" });
    };

    const handleMouseDown = () => {
      cursor.css({ transform: "scale(.2)" });
    };

    const handleMouseUp = () => {
      cursor.css({ transform: "scale(1)" });
    };

    const handleElementMouseEnter = () => {
      cursor.css({ transform: "scale(3.2)" });
      cursor.addClass('opacity-low');
    };

    const handleElementMouseLeave = () => {
      cursor.css({ transform: "scale(1)" });
      cursor.removeClass('opacity-low');
    };

    $(window).on('mousemove', handleMouseMove);
    $(window).on('mouseleave', handleMouseLeave);
    $(window).on('mouseenter', handleMouseEnter);
    $(window).on('mousedown', handleMouseDown);
    $(window).on('mouseup', handleMouseUp);

    $(".btn, .zoom, .nav-link, a img, .footer-container a, h1, button, .benefit, a#contactanosbutton")
      .on('mouseenter', handleElementMouseEnter)
      .on('mouseleave', handleElementMouseLeave);

    return () => {
      $(window).off('mousemove', handleMouseMove);
      $(window).off('mouseleave', handleMouseLeave);
      $(window).off('mouseenter', handleMouseEnter);
      $(window).off('mousedown', handleMouseDown);
      $(window).off('mouseup', handleMouseUp);

      $(".btn, .zoom, .nav-link, a img, .footer-container a, h1, button, .benefit, a#contactanosbutton")
        .off('mouseenter', handleElementMouseEnter)
        .off('mouseleave', handleElementMouseLeave);
    };
  }, []);

  return <div className="cursor"></div>;
};

export default CustomCursor;
