import React from 'react';
import logo from '../assets/images/logo.png'; 

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-content">
        <img src={logo} alt="Loader" />
      </div>
    </div>
  );
};

export default Loader;
