import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosComunicacion from '../../components/BeneficiosComunicacion';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logocomunicacion from '../../assets/images/comunicacion/logocomunicacion.png';

import path_to_icon1 from '../../assets/images/comunicacion/sdwan/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/comunicacion/sdwan/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/comunicacion/sdwan/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/comunicacion/sdwan/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/comunicacion/sdwan/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/comunicacion/sdwan/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/comunicacion/sdwan/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/comunicacion/sdwan/path_to_icon8.png';


const SdWan = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Conecta</b> todos los locales de forma privada, segura, rápida y simple.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Asegura la conexión</b>, administrando hasta 2 enlaces, automatizando la contingencia y el balanceo de cargas. '
    },
    {
      icon: path_to_icon3,
      text: '<b>Mejora la experiencia de conectividad</b> cpermitiendo maximizar el rendimiento de tus enlaces y priorizando el tráfico.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Centraliza</b> la administración y configuración en una única consola web, reduciendo tiempos y costos.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: '<b>Simplicidad.</b> Todo desde un único panel de control WEB.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Seguridad.</b> Firewall de alto nivel, AntiMalware y Filtrado de contenido incorporado.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Capacidad.</b> Mayor rendimiento con optimización automática de la red.'
    },
    {
      icon: path_to_icon8,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logocomunicacion}
        imgAlt="Logo de seguridad digital"
        description="Potenciá tu empresa implementando herramientas de última generación para estar siempre conectado con tus clientes."
      />

      <SubHeader
        title="<b>SD-WAN Movistar</b>"
        subtitle1="La mejor experiencia de conectividad privada."
        subtitle2="SD-WAN Movistar es una solución que permite disfrutar de redes empresariales seguras, con la mejor experiencia de conectividad privada y con capacidad para gestionar múltiples enlaces simultáneos que aseguran el funcionamiento constante de los negocios."
      />

      <MarqueeText text="SD-WAN Movistar" />

      <BeneficiosComunicacion/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default SdWan;
