import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosIot from '../../components/BeneficiosIot';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoiot from '../../assets/images/iot/logoiot.png';

import path_to_icon1 from '../../assets/images/iot/locdeflotas/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/iot/locdeflotas/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/iot/locdeflotas/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/iot/locdeflotas/path_to_icon4.png';
import path_to_icon5 from '../../assets/images/iot/locdeflotas/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/iot/locdeflotas/path_to_icon6.png';

import path_to_icon7 from '../../assets/images/iot/locdeflotas/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/iot/locdeflotas/path_to_icon8.png';
import path_to_icon9 from '../../assets/images/iot/locdeflotas/path_to_icon9.png';


const LocDeFlotas = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Monitoreo de toda la flota de vehículos o personas</b> en tiempo real.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Administra</b> el mantenimiento de las unidades a través de GPS con odómetro/horómetro.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Analiza el recorrido histórico</b> de toda flota de vehículos.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Accede a más de 20 recortes de distancias recorridas</b> paradas realizadas, excesos de velocidad y más.'
    },
    {
      icon: path_to_icon5,
      text: '<b>Genera</b> fácilmente múltiples alarmas, cercas, rutas y puntos de interés.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Comparte ubicación en tiempo real</b> por un tiempo determinado con un proveedor o cliente.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon7,
      text: 'Amplia experiencia en el mercado de rastreo satelital.'
    },
    {
      icon: path_to_icon8,
      text: 'Adaptabilidad a las necesidades del negocio, aportando soluciones personalizadas.'
    },
    {
      icon: path_to_icon9,
      text: 'Atención personalizada con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logoiot}
        imgAlt="Logo de internet de las cosas"
        description="Optimizá tus procesos logísticos y operativos implementando nuestras soluciones para lograr una gestión en tiempo real a través de la interconexión de dispositivos."
      />

      <SubHeader
        title="Localización de <b>Flotas</b>"
        subtitle1="Es un <b>sistema de rastreo satelital que permite saber la ubicación en tiempo real de la flota vehicular de la empresa</b>, mejorando así la seguridad y optimizando la productividad de la misma."
        subtitle2=""
      />

      <MarqueeText text="Localización de Flotas" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/lZNl-zneJ0M?si=T7Z3_qRx5iTVekZi"
        floatText="Monitoreá la flota de vehículos"
        bottomText="<b>Mayor productividad</b> y seguridad para los vehículos."
      />

      <BeneficiosIot/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default LocDeFlotas;
