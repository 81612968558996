import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosIot from '../../components/BeneficiosIot';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoiot from '../../assets/images/iot/logoiot.png';

import path_to_icon1 from '../../assets/images/iot/controldepersonal/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/iot/controldepersonal/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/iot/controldepersonal/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/iot/controldepersonal/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/iot/controldepersonal/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/iot/controldepersonal/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/iot/controldepersonal/path_to_icon7.png';


const ControlDePersonal = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Accede vía Cloud</b>, no hace falta instalar software ni licencia.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Multiples dispositivos</b> permiten acceder a los reportes desde cualquier dispositivo.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Reduce costos y tiempo</b> en la gestión de personal.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Presenta informes</b> de forma clara y resumida.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: 'Capacidad de gestionar información en tiempo real y sin interrupciones con software especializado.'
    },
    {
      icon: path_to_icon6,
      text: 'Flexibilidad para adaptarse a las necesidades de cada empresa.'
    },
    {
      icon: path_to_icon7,
      text: 'Atención personalizada con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logoiot}
        imgAlt="Logo de internet de las cosas"
        description="Optimizá tus procesos logísticos y operativos implementando nuestras soluciones para lograr una gestión en tiempo real a través de la interconexión de dispositivos."
      />

      <SubHeader
        title="Control de <b>Personal</b>"
        subtitle1="Organizá y gestioná los turnos de tu negocio de manera fácil y centralizada."
        subtitle2="Sistema de marcación mixta que se adecua a las necesidades del cliente en función de su estructura, formato de trabajo y cantidad de empleados."
      />

      <MarqueeText text="Control de Personal" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/noT1nWm7vOk?si=6rMYRuL29vkifjRZ"
        floatText="Simplifica el control de personal generando informes simples."
        bottomText="Esta herramienta permite controlar los horarios de entrada y salida del personal mediante lectores de huellas."
      />

      <BeneficiosIot/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default ControlDePersonal;
