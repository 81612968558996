import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosSeguridad from '../../components/BeneficiosSeguridad';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoseguridad from '../../assets/images/seguridad/logoseguridad.png';

import path_to_icon1 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon3.png';

import path_to_icon4 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon4.png';
import path_to_icon5 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/seguridad/serviciosprofesionales/path_to_icon6.png';



const ServiciosProfesionales = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Hacking ético</b> va un paso más allá del análisis de vulnerabilidades, realizando simulacros de ataques reales sobre los activos como los que hace un auténtico hacker, con el objetivo de evaluar desde la perspectiva de un atacante, permitiendo tener una visión y alcance más profundo de los hallazgos.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Análisis de vulnerabilidades</b> consiste en evaluar los activos de la organización, analizando y clasificando las vulnerabilidades identificadas. Presentando un informe con los hallazgos y la clasificación de criticidad de estos. '
    },
    {
      icon: path_to_icon3,
      text: '<b>CSIRT</b> es un equipo de especialistas en ciberseguridad disponible para responder a incidentes de seguridad informática, como ataques cibernéticos o brechas de seguridad. Su función es investigar, identificar, detectar, analizar y recomendar mitigaciones, minimizando el impacto en la organización.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon4,
      text: '<strong>Buenas prácticas & cumplimiento normativo</strong> que todas las normas y marcos regulatorios recomiendan y exigen.'
    },
    {
      icon: path_to_icon5,
      text: '<strong>Perspectiva imparcial</strong> y neutral asegurando que sus procesos y activos son evaluados por una organización externa, garantizando imparcialidad al entregar el servicio.'
    },
    {
      icon: path_to_icon6,
      text: '<strong>Postura de seguridad mejorada</strong> al incorporar al disponer de mayor visibilidad de la postura de seguridad, permitiendo a los tomadores de decisiones saber que pasos dar.'
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logoseguridad}
        imgAlt="Logo de servicios profesionales"
        description="Protegé la información de tu empresa con servicios personalizados y tecnologías de última generación, fortaleciendo tu postura de seguridad de manera profesional y eficaz, asegurando el acceso continuo a la información."
      />

      <SubHeader
        title="Servicios <b>Profesionales</b>"
        subtitle1="Soluciones llave en mano y asesoramiento ofrecidos por expertos en ciberseguridad, diseñados para dar visibilidad de tus activos digitales y la postura de seguridad. Dando especial atención al cuidado de la confidencialidad y disponibilidad de la información de los recursos a evaluar."
        subtitle2="Estos servicios se adaptan a las necesidades específicas de cada organización, proporcionando evaluaciones de riesgos, recomendaciones basadas en oportunidades de mejora identificadas, y más."
      />

      <MarqueeText text="Servicios Profesionales" />

      <BeneficiosSeguridad/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default ServiciosProfesionales;
