import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosCloud from '../../components/BeneficiosCloud';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logocloud from '../../assets/images/cloud/logocloud.png';

import path_to_icon1 from '../../assets/images/cloud/msft365/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/cloud/msft365/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/cloud/msft365/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/cloud/msft365/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/cloud/msft365/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/cloud/msft365/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/cloud/msft365/path_to_icon7.png';


const Msft365 = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Mejora la eficiencia del teletrabajo</b> dando las herramientas adecuadas para el desempeño de las tareas.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Accede a la nube</b> para encontrar documentos de forma rápida.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Usa aplicaciones conocidas</b> de forma segura.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Respalda</b> el trabajo de forma segura y sencilla.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: '<b>Securizá y centralizá</b> toda tu información y administrá los usuarios que acceden a ella.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Facturación local</b> que permite descontar IVA.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Atención personalizada</b>. con un equipo de expertos en Microsoft 365.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logocloud}
        imgAlt="Logo de seguridad digital"
        description="Impulsá tu negocio con nuestras soluciones de infraestructura y servicios en la nube, logra eficiencia, escalabilidad y colaboración de forma segura en todas tus operaciones."
      />

      <SubHeader
        title="Microsoft <b>365</b>"
        subtitle1="Microsoft es una herramienta Cloud para potenciar la productividad de las organizaciones y el trabajo colaborativo."
        subtitle2="Reúne aplicaciones de comunicación, producción de contenidos y análisis, accesibles desde cualquier dispositivo y cualquier lugar. Integra correo electrónico corporativo, calendarios y archivos compartidos, videoconferencia y mensajería instantánea. Permite la trazabilidad del trabajo de los usuarios, repositorios de información administrados por la organización, el respaldo automático de toda la información y un marco de seguridad que garantiza la protección de los datos de tu empresa."
      />

      <MarqueeText text="Microsoft 365"/>

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/_Ya1gUPVtsA?si=kGrpbJDG8KJLp61z"
        floatText="Accesibilidad e integración para trabajar de forma remota pero sincronizada."
        bottomText="<b>Una suite</b>de herramientas integral en permanente evolución."
      />

      <BeneficiosCloud/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default Msft365;
