import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosIot from '../../components/BeneficiosIot';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoiot from '../../assets/images/iot/logoiot.png';

import path_to_icon1 from '../../assets/images/iot/kite/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/iot/kite/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/iot/kite/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/iot/kite/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/iot/kite/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/iot/kite/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/iot/kite/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/iot/kite/path_to_icon8.png';


const Kite = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Aumenta la eficacia operativa</b> evitando desplazamientos innecesarios.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Incrementa</b> la seguridad y privacidad permitiendo solo las comunicaciones autorizadas.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Simplifica el acceso a los dispositivos</b> IoT en la nube.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Conecta</b> y da cobertura de servicio con un SIM global.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Previene usos inapropiados</b> y controla el consumo de tráfico.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: 'Plataforma de <b>desarrollo propio</b>.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Flexibilidad y agilidad</b> para adaptarnos a tus necesidades.'
    },
    {
      icon: path_to_icon7,
      text: '<b>SIM global</b>. Alta disponibilidad de servicio en cobertura y conectividad.'
    },
    {
      icon: path_to_icon8,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logoiot}
        imgAlt="Logo de internet de las cosas"
        description="Optimizá tus procesos logísticos y operativos implementando nuestras soluciones para lograr una gestión en tiempo real a través de la interconexión de dispositivos."
      />

      <SubHeader
        title="<b>KITE</b>"
        subtitle1="Desarrollada con recursos propios de Telefónica Tech, Kite permite controlar y monitorizar el parque de líneas en tiempo real y de forma remota, desde cualquier lugar del mundo."
        subtitle2="Es compatible con un amplio abanico de tarjetas SIM, y gestiona las nuevas tecnologías de conectividad de red IoT, además de la conectividad celular tradicional."
      />

      <MarqueeText text="Kite" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/qgiHcC3J_b0?si=oYDV-6Hl0rWX1MI_"
        floatText="La solución de conectividad autogestionada"
        bottomText="<b>Controlá las conexiones en tiempo real.</b>"
      />

      <BeneficiosIot/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default Kite;
