import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosIot from '../../components/BeneficiosIot';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoiot from '../../assets/images/iot/logoiot.png';

import path_to_icon1 from '../../assets/images/iot/gestiondetareas/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/iot/gestiondetareas/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/iot/gestiondetareas/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/iot/gestiondetareas/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/iot/gestiondetareas/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/iot/gestiondetareas/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/iot/gestiondetareas/path_to_icon7.png';


const GestionDeTareas = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Aumenta</b> la calidad del servicio.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Envía avisos automáticos al cliente.</b>'
    },
    {
      icon: path_to_icon3,
      text: '<b>Disminuye costos operativos</b> y eliminar el uso del papel en formularios.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Genera nuevas oportunidad de negocios y permitir mayor gestión por parte del equipo.</b>'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: 'Toda la <b>información estadística</b> de las gestiones a tu alcance.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Adaptabilidad</b> a las necesidades del negocio, aportando soluciones personalizadas.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logoiot}
        imgAlt="Logo de internet de las cosas"
        description="Optimizá tus procesos logísticos y operativos implementando nuestras soluciones para lograr una gestión en tiempo real a través de la interconexión de dispositivos."
      />

      <SubHeader
        title="<b>Gestión de Tareas</b>"
        subtitle1="Solución que permite registrar y asignar tareas al personal y monitorear con detalle sus actividades a través de Tablets o celulares con sistema operativo Android."
        subtitle2=""
      />

      <MarqueeText text="Gestión de Tareas" />

      <BeneficiosIot/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default GestionDeTareas;
