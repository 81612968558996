import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import fondo from '../assets/images/fondo_seguridad.png';
import anime from 'animejs/lib/anime.es.js';

const Seguridad = () => {
  const navigate = useNavigate();

  useEffect(() => {
    anime({
      targets: '.seguridad-list button',
      translateX: [-300, 0], // Cambia translateY a translateX para que venga desde la izquierda
      opacity: [0, 1],
      delay: anime.stagger(300), // Aumenta el delay entre botones para que no se solapen
      easing: 'easeOutExpo',
      duration: 2400 // Aumenta la duración para que sea más lenta
    });

    anime({
      targets: '.image-fondo-container img',
      translateX: [300, 0], // Haz que la imagen venga desde la derecha
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 3000 // Aumenta la duración para hacer la animación más lenta
    });
    
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="seguridad container">
      <div className="image-fondo-container">
        <img src={fondo} alt="Fondo"></img>
      </div>
      <div className="seguridad-list">
        <button onClick={() => handleNavigation('/seguridad/seguridad-mdm')}>Seguridad MDM</button>
        <button onClick={() => handleNavigation('/seguridad/ciberseguridad-gestionada')}>Ciberseguridad Gestionada</button>
        <button onClick={() => handleNavigation('/seguridad/siem')}>SIEM</button>
        <button onClick={() => handleNavigation('/seguridad/servicios-profesionales')}>Servicios Profesionales</button>
      </div>
    </div>
  );
};

export default Seguridad;
