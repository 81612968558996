import React, { useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptchaComponent = ({ onVerify }) => {
    const recaptchaRef = useRef(null);

    // Ejecuta reCAPTCHA automáticamente al cargar el componente
    useEffect(() => {
        if (recaptchaRef.current) {
            recaptchaRef.current.execute(); // Ejecuta reCAPTCHA al cargar
        }
    }, []);

    const handleVerify = (token) => {
        onVerify(token); // Pasar el token al padre
    };

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Agrega tu clave del sitio
            size="invisible"
            onChange={handleVerify} // Verificación exitosa
        />
    );
};

export default ReCaptchaComponent;
