const isValidRUT = (rut) => {
    // Eliminar puntos y guiones
    rut = rut.replace(/[.-]/g, '');
    
    // Verificar que el RUT tenga 12 dígitos
    if (!/^[0-9]{12}$/.test(rut)) {
        return false;
    }
    
    // Cálculo del dígito verificador
    const digits = rut.slice(0, -1).split('').map(Number);
    const verifier = parseInt(rut.slice(-1), 10);
    
    let sum = 0;
    let factor = 2;

    for (let i = digits.length - 1; i >= 0; i--) {
        sum += digits[i] * factor;
        factor = (factor === 9) ? 2 : factor + 1;
    }

    const calculatedVerifier = (sum % 11 === 0) ? 0 : 11 - (sum % 11);
    
    return verifier === calculatedVerifier;
};


module.exports = { isValidRUT };
