import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';

const TitleService = ({ linkTo, imgSrc, imgAlt, description }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    anime({
      targets: titleRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 500,
      duration: 1000,
      easing: 'easeOutExpo',
    });
  }, []);

  return (
    <div className="preheader" ref={titleRef}>
      <Link to={linkTo}>
        <img src={imgSrc} alt={imgAlt} className="logo" />
      </Link>
      <p>{description}</p>
    </div>
  );
};

export default TitleService;
