import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const WhyComponent = ({ reasons }) => {
  const whyRef = useRef(null);

  useEffect(() => {
    anime({
        targets: whyRef.current,
        translateY: [-50, 0],
        opacity: [0, 1],
        delay: 3500,
        duration: 1000,
        easing: 'easeOutExpo',
    });
  }, []); 
  
  return (
    <section className='why-movistar' ref={whyRef}>
        <h3>¿Por qué elegir Movistar?</h3>
        <div className='reasoncontainer'>
            {reasons.map((reason, index) => (
                <div className='reason' key={index}>
                    <img src={reason.icon} alt={`Icon ${index + 1}`} />
                    <p dangerouslySetInnerHTML={{ __html: reason.text }}></p>
                </div>
            ))}
        </div>
    </section>
  );
};

export default WhyComponent;
