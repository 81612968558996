import React, { useRef, useEffect, useState } from 'react';

const MarqueeText = ({ text, speed = 100 }) => {
  const marqueeRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (marqueeRef.current) {
      setTextWidth(marqueeRef.current.scrollWidth); // Calcula el ancho total del texto
      setContainerWidth(marqueeRef.current.offsetWidth); // Calcula el ancho del contenedor
    }
  }, [text]);

  // Calcular duración: tiempo = distancia / velocidad
  const distance = textWidth + containerWidth; // Distancia total que recorre el texto (de un lado al otro)
  const duration = distance / speed; // Duración de la animación basada en la velocidad constante

  return (
    <div className="marquee-container" ref={marqueeRef}>
      <div
        className="marquee"
        style={{ animationDuration: `${duration}s` }} // Aplica la duración calculada
      >
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default MarqueeText;
