import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const Beneficios = () => {
  const benefitsRef = useRef(null);

  useEffect(() => {
    // Animación de la sección
    anime({
      targets: benefitsRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 3000,
      duration: 1000,
      easing: 'easeOutExpo',
    });

    // Animación del gradiente con movimiento horizontal continuo
    const animateGradient = () => {
      anime({
        targets: '#gradient',
        x1: ['0%', '100%'],
        x2: ['100%', '200%'],
        easing: 'linear',
        duration: 5000,
        loop: true,
        direction: 'alternate'
      });
    };
    
    animateGradient();
  }, []);

  return (
    <>
      <section className='benefits-section-text' ref={benefitsRef} style={{ background: 'none' }}>
        <svg viewBox="0 0 1200 200" style={{ width: '100%', height: 'auto' }}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#d68ae9" />
              <stop offset="25%" stopColor="#ee12e7" />
              <stop offset="50%" stopColor="#6f056b" />
              <stop offset="75%" stopColor="#ee12e7" />
              <stop offset="100%" stopColor="#bc48da" />
            </linearGradient>,
          </defs>
          <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"
            fontFamily="'Telefonica Bold', sans-serif" fontSize="200" fill="none" stroke="url(#gradient)" strokeWidth="1">
            Beneficios
          </text>
        </svg>
      </section>
    </>
  );
};

export default Beneficios;
