import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Página no encontrada</h1>
      <p style={styles.paragraph}>Lo sentimos, la página que buscas no existe.</p>
      <Link to="/" style={styles.link}>Volver a la página principal</Link>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  heading: {
    fontSize: '48px',
    margin: '0 0 20px',
    color: '#ffffff',
  },
  paragraph: {
    fontSize: '18px',
    margin: '0 0 20px',
    color: '#ffffff',
  },
  link: {
    fontSize: '16px',
    color: '#ffffff',
    textDecoration: 'none',
    border: '1px solid #ffffff',
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'background-color 0.3s, color 0.3s',
  },
};

export default NotFound;
