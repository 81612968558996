// LoadingContext.js
import React, { useState, createContext, useCallback } from 'react';

export const LoadingContext = createContext({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {}
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
