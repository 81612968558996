import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosIot from '../../components/BeneficiosIot';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logoiot from '../../assets/images/iot/logoiot.png';

import path_to_icon1 from '../../assets/images/iot/facturacionelectronica/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/iot/facturacionelectronica/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/iot/facturacionelectronica/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/iot/facturacionelectronica/path_to_icon4.png';
import path_to_icon5 from '../../assets/images/iot/facturacionelectronica/path_to_icon5.png';

import path_to_icon6 from '../../assets/images/iot/facturacionelectronica/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/iot/facturacionelectronica/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/iot/facturacionelectronica/path_to_icon8.png';
import path_to_icon9 from '../../assets/images/iot/facturacionelectronica/path_to_icon9.png';


const FacturacionElectronica = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Facturaración Electrónica</b> en línea las 24 horas.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Precarga productos,</b> precios y clientes para una rápida facturación.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Cumple con todas las normativas de DGI</b> para facturación electrónica.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Reduce costos de impresión,</b> envíos y almacenamiento de facturas.'
    },
    {
      icon: path_to_icon5,
      text: '<b>Permite reportes de gestión y registro contable.</b>'
    }
  ];

  
  const reasons = [
    {
      icon: path_to_icon6,
      text: 'Interfaz sumamente ágil y sencilla.'
    },
    {
      icon: path_to_icon7,
      text: 'Emitir todos los comprobantes electrónicos exigidos por la DGI desde cualquier dispositivo.'
    },
    {
      icon: path_to_icon8,
      text: 'Agrupación de productos por familia y subfamilias.'
    },
    {
      icon: path_to_icon9,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];


  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logoiot}
        imgAlt="Logo de cloud"
        description="Optimizá tus procesos logísticos y operativos implementando nuestras soluciones para lograr una gestión en tiempo real a través de la interconexión de dispositivos."
      />

      <SubHeader
        title="Facturación <b>Electrónica</b>"
        subtitle1="Una solución simple y confiable para adherirse al régimen de facturación electrónica."
        subtitle2="Una forma más eficientemente de gestionar tu negocio."
      />

      <MarqueeText text="Facturación Electrónica" />


      <BeneficiosIot/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default FacturacionElectronica;
