import React from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from './LogoSVG'
import headertext from '../assets/images/headertext.png';


const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <LogoSVG/>
        </Link>
      </div>
      <div className='hide-mobile'>
        <img src={headertext} alt="HeaderText" />
      </div>
    </header>
  );
};

export default Header;
