import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosComunicacion from '../../components/BeneficiosComunicacion';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logocomunicacion from '../../assets/images/comunicacion/logocomunicacion.png';


import path_to_icon1 from '../../assets/images/comunicacion/pdti/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/comunicacion/pdti/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/comunicacion/pdti/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/comunicacion/pdti/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/comunicacion/pdti/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/comunicacion/pdti/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/comunicacion/pdti/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/comunicacion/pdti/path_to_icon8.png';


const Pdti = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Laptop nueva</b>, financiada en el contrato. Se puede elegir el modelo de laptop y la cantidad de gigas de navegación más adecuada para la empresa.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Cuotas asequibles</b>, financiando los equipos hasta en 36 meses.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Antivirus oficial</b>, protegé las computadoras ante diferentes amenazas.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Mantenimiento anual</b> de las laptops.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: '<b>Las computadoras</b> son activos de tu empresa.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Plataforma de autogestión</b> para administrar y gestionar de forma totalmente autónoma tareas y actividades.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Conectividad</b> gratis durante todo el contrato.'
    },
    {
      icon: path_to_icon8,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logocomunicacion}
        imgAlt="Logo de seguridad digital"
        description="Potenciá tu empresa implementando herramientas de última generación para estar siempre conectado con tus clientes."
      />

      <SubHeader
        title="<b>PDTI</b>"
        subtitle1="Puesto de Trabajo Inteligente"
        subtitle2="Solución integral centralizada del puesto de trabajo de los colaboradores. En un solo plan se incluye: computadora de última generación, conectividad, soporte técnico, antivirus y mantenimiento durante la vigencia del contrato."
      />

      <MarqueeText text="PDTI" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/zMttVKA2hX0?si=Wn32TBL0paGIsIP-"
        floatText="Un servicio que se adapta a las necesidades de la empresa."
        bottomText="<b>Conectividad gratuita,</b> con router sin ningún costo. Los gigas incluidos en el plan son gratuitos durante todo el período de 3 años del contrato."
      />

      <BeneficiosComunicacion/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default Pdti;
