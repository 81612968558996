import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import fondo from '../assets/images/fondo_iot.png';
import anime from 'animejs/lib/anime.es.js';

const Iot = () => {
  const navigate = useNavigate();

  useEffect(() => {
    anime({
      targets: '.iot-list button',
      translateX: [-300, 0], // Cambia translateY a translateX para que venga desde la izquierda
      opacity: [0, 1],
      delay: anime.stagger(300), // Aumenta el delay entre botones para que no se solapen
      easing: 'easeOutExpo',
      duration: 2400 // Aumenta la duración para que sea más lenta
    });

    anime({
      targets: '.image-fondo-container img',
      translateX: [300, 0], // Haz que la imagen venga desde la derecha
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 3000 // Aumenta la duración para hacer la animación más lenta
    });

  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="iot container">
      <div className="image-fondo-container">
        <img src={fondo} alt="Fondo"></img>
      </div>
      <div className="iot-list">
        <button onClick={() => handleNavigation('/iot/kite')}>Kite</button>
        <button onClick={() => handleNavigation('/iot/loc-de-flotas')}>Loc de flotas</button>
        <button onClick={() => handleNavigation('/iot/control-de-personal')}>Control de personal</button>
        <button onClick={() => handleNavigation('/iot/gestion-de-tareas')}>Gestión de tareas</button>
        <button onClick={() => handleNavigation('/iot/facturacion-electronica')}>Facturación Electrónica</button>
      </div>
    </div>
  );
};

export default Iot;
