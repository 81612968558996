import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';
import personas from '../assets/images/personas.png';
import icon_comunicacion_desktop from '../assets/images/icon_comunicacion_desktop.png';
import icon_cloud_desktop from '../assets/images/icon_cloud_desktop.png';
import icon_iot_desktop from '../assets/images/icon_iot_desktop.png';
import icon_seguridad_desktop from '../assets/images/icon_seguridad_desktop.png';
import icon_contacto_desktop from '../assets/images/icon_contacto_desktop.png';



const Home = () => {
  const headingRef = useRef(null);
  const buttonsRef = useRef([]);

  useEffect(() => {
    anime({
      targets: headingRef.current,
      translateY: [-100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeOutExpo',
    });

    anime({
      targets: buttonsRef.current,
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: anime.stagger(200, { start: 500 }),
      easing: 'easeOutExpo',
    });
  }, []);

  return (
    <div className="home">
      <div className='homeleft'>
        <div>
          <h1 ref={headingRef}>Movistar Empresas va con vos.</h1>
          <h1 ref={headingRef} className="blue">Descubrí por qué elegirnos</h1>
        </div>  
        
        <div className="button-container">
          <Link to="/contacto" ref={el => buttonsRef.current[0] = el} id="butt_contacto"><img src={icon_contacto_desktop} alt='icon'></img></Link>
          <Link to="/comunicacion-y-conectividad" ref={el => buttonsRef.current[1] = el} id="butt_comunicacion"><img src={icon_comunicacion_desktop} alt='icon'></img></Link>
          <Link to="/cloud" ref={el => buttonsRef.current[2] = el} id="butt_cloud"><img src={icon_cloud_desktop} alt='icon'></img></Link>
          <Link to="/iot" ref={el => buttonsRef.current[3] = el} id="butt_iot"><img src={icon_iot_desktop} alt='icon'></img></Link>
          <Link to="/seguridad" ref={el => buttonsRef.current[4] = el} id="butt_seguridad"><img src={icon_seguridad_desktop} alt='icon'></img></Link>
        </div>
      </div>
      <div className='homeright'>
        <img src={personas} alt="Personas Movistar"></img>
      </div>
    </div>
  );
};

export default Home;
