import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const AnimatedBackground = () => {
  const ballsRef = useRef([]);

  useEffect(() => {
    ballsRef.current.forEach((ball, index) => {
      const animateBall = () => {
        anime({
          targets: ball,
          translateX: anime.random(0, window.innerWidth - 50),
          translateY: anime.random(0, window.innerHeight - 50),
          duration: 3000, 
          easing: 'easeInOutQuad',
          complete: animateBall, 
        });
      };
      animateBall(); 
    });
  }, []);

  return (
    <div className="animated-background">
      {[...Array(3)].map((_, i) => (
        <div 
          key={i} 
          className="ball" 
          ref={el => ballsRef.current[i] = el}
        />
      ))}
    </div>
  );
};

export default AnimatedBackground;
