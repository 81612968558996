import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';  
import anime from 'animejs/lib/anime.es.js';
import fondo_contacto_desktop from '../assets/images/butt_iot_desktop.png';
import ReCaptchaComponent from './ReCaptchaComponent';
import { isValidRUT } from '../shared/js/validation'; // Ajusta la ruta relativa

const ContactFormMovistarLink = () => {
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [recaptchaToken, setRecaptchaToken] = useState(null); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        anime({
            targets: '#aplicar h1, h2, p, #registrationForm .form-group',
            translateY: [-50, 0],
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1000
        });

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleReCaptchaVerify = (token) => {
        setRecaptchaToken(token); 
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!recaptchaToken) {
            Swal.fire({
                icon: 'error',
                title: 'Error de reCAPTCHA',
                text: 'Por favor, verifica que no eres un robot.',
            });
            return;
        }

        setIsSubmitting(true);
    
        const formData = {
            name: event.target.name.value.trim(),
            phone: event.target.phone.value.trim(),
            email: event.target.email.value.trim(),
            rut: event.target.rut.value.trim(),
            recaptchaToken: recaptchaToken 
        };

        // Validaciones
        if (!formData.name || formData.name.length < 3) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El nombre debe tener al menos 3 caracteres',
            });
            setIsSubmitting(false);
            return;
        }

        const phonePattern = /^[0-9]{9}$/; 
        if (!phonePattern.test(formData.phone)) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El número de teléfono debe ser de 9 dígitos',
            });
            setIsSubmitting(false);
            return;
        }
    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        if (!emailPattern.test(formData.email)) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'Por favor ingrese un correo electrónico válido',
            });
            setIsSubmitting(false);
            return;
        }

        if (formData.rut && !isValidRUT(formData.rut)) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El RUT ingresado no es válido',
            });
            setIsSubmitting(false);
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000/api";

        axios.post(`${apiUrl}/uploadmovistarlink`, formData)
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Formulario enviado con éxito',
                    showConfirmButton: false,
                    timer: 1500
                });
    
                event.target.reset();
                setRecaptchaToken(null);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error enviando el formulario',
                    text: error.response ? error.response.data.message : error.message,
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const containerStyle = {
        display: 'flex',
        maxWidth: '1200px',
        margin: 'auto',
        padding: isMobile ? '20px 20px 60px 20px' : '20px',
        color: '#fff',
        flexDirection: isMobile ? 'column' : 'row', 
    };

    const textStyle = {
        flex: '1',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        marginRight: '20px',
        justifyContent: 'flex-start',
    };

    const formContainerStyle = {
        flex: '1',
        padding: '20px',
        borderRadius: '10px',
    };

    const headingStyle = {
        textAlign: 'left',
        marginBottom: '20px',
        color: '#fff',
        marginTop: '0px',        
        fontSize: '3em',
        lineHeight: '1em',
    };

    const headingStyle2 = {
        marginTop: '0px',
        marginBottom: '20px',
        color: '#fff',
        fontSize: '1.5em',
    };

    const labelStyle = {
        color: '#fff',
        fontWeight: 'bold',
    };

    const inputStyle = {
        borderRadius: '5px',
        border: '1px solid #ddd',
        backgroundColor: '#cacaca3b',
        color: '#fff',
        padding: '10px',
        fontSize: '16px',
        width: '100%',
        marginBottom: '20px',
        marginRight: '10px'
    };

    const buttonStyle = {
        minWidth: '200px',
        padding: '10px',
        fontSize: '20px',
        borderRadius: '5px',
        color: 'white',
        float: 'left',
        transition: 'background-color 0.3s ease, opacity 0.3s ease',
        cursor: isSubmitting ? 'not-allowed' : 'pointer',
        backgroundImage: `url(${fondo_contacto_desktop})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        opacity: isSubmitting ? 0.5 : 1, 
        filter: 'grayscale(100%)'
    };

    return (
        <div id="aplicar" style={containerStyle} className="mt-5">
            <div style={textStyle}>
                <h1 style={headingStyle}>Movistar Link</h1>
                <p style={headingStyle2}>Déjanos tus datos y un asesor te contactará.</p>
            </div>

            <div style={formContainerStyle}>
                <form id="registrationForm" onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label htmlFor="name" style={labelStyle}>Nombre</label>
                        <input type="text" className="form-control" id="name" name="name" style={inputStyle} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="rut" style={labelStyle}>RUT</label> {/* Cambiado de "documento" a "rut" */}
                        <input 
                            type="text" 
                            className="form-control" 
                            id="rut" 
                            name="rut" 
                            style={inputStyle} 
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.-]/g, '')} // Solo números, puntos y guiones
                        />
                    </div> 

                    <div className="form-group">
                        <label htmlFor="email" style={labelStyle}>Correo Electrónico</label>
                        <input type="email" className="form-control" id="email" name="email" style={inputStyle} required />
                    </div>   

                    <div className="form-group">
                        <label htmlFor="phone" style={labelStyle}>Celular</label>
                        <input 
                            type="tel" 
                            className="form-control" 
                            id="phone" 
                            name="phone" 
                            style={inputStyle} 
                            required 
                            maxLength="9" 
                            pattern="\d{9}" 
                            inputMode="numeric"
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                        />
                    </div>      

                    <ReCaptchaComponent onVerify={handleReCaptchaVerify} />
            
                    <button type="submit" id="aplicarbutton" className="boton btn btn-primary" style={buttonStyle} disabled={isSubmitting}>Aplicar</button>
                </form>
            </div>
        </div>
    );
};

export default ContactFormMovistarLink;
