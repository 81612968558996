import React from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import VideoContainer from '../../components/VideoContainer';
import BeneficiosCloud from '../../components/BeneficiosCloud';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';

import logocloud from '../../assets/images/cloud/logocloud.png';

import path_to_icon1 from '../../assets/images/cloud/omnicanal/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/cloud/omnicanal/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/cloud/omnicanal/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/cloud/omnicanal/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/cloud/omnicanal/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/cloud/omnicanal/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/cloud/omnicanal/path_to_icon7.png';
import path_to_icon8 from '../../assets/images/cloud/omnicanal/path_to_icon8.png';


const Omnicanal = () => {

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Establece</b> una relación más cercana con los clientes reduciendo los tiempos de respuesta.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Trabaja en la nube</b> evitando altos costos de infraestructura.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Cuenta con la posibilidad de integrarse con sistemas de Ecommerce</b> mejorando los indices de satisfacción del canal.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Cuenta con módulos de analítica que permite conocer en tiempo real</b> las métricas para optimizar recursos.'
    },
    {
      icon: path_to_icon5,
      text: '<b>Incrementa la productividad</b> y los índices de satisfacción de tus clientes redundando en mayores beneficios para tu negocio.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon6,
      text: '<b>Adaptabilidad</b> a las necesidades del negocio, aportando soluciones personalizadas.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Asesoramiento posventa</b> para dar el respaldo necesario para el óptimo funcionamiento.'
    },
    {
      icon: path_to_icon8,
      text: '<b>Atención personalizada</b> con un equipo especializado 24/7.'
    }
  ];



  return (
    <div className='container'>
      <TitleService
        linkTo="/"
        imgSrc={logocloud}
        imgAlt="Logo de seguridad digital"
        description="Impulsá tu negocio con nuestras soluciones de infraestructura y servicios en la nube, logra eficiencia, escalabilidad y colaboración de forma segura en todas tus operaciones."
      />

      <SubHeader
        title="Movistar <b>Omnicanal</b>"
        subtitle1="El siguiente paso en gestión de tus clientes."
        subtitle2="Es una solución para que las empresas puedan gestionar las interacciones con sus clientes desde un solo lugar y de forma simple, permitiendo un contacto más personalizado, mejorando los indicadores de perfomance de atención y optimizando los recursos del negocio."
      />

      <MarqueeText text="Movistar Omnicanal" />

      <VideoContainer
        videoSrc="https://www.youtube.com/embed/QohGLAQd6Zc?si=2hilskL3kOWSWnON"
        floatText="El siguiente paso en gestión de consultas."
        bottomText="<b>Centralización</b> seguimiento y automatización de las gestiones de tus clientes."
      />

      <BeneficiosCloud/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default Omnicanal;
