import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import icon_comunicacion_desktop from '../assets/images/icon_comunicacion_desktop.png';
import icon_cloud_desktop from '../assets/images/icon_cloud_desktop.png';
import icon_iot_desktop from '../assets/images/icon_iot_desktop.png';
import icon_seguridad_desktop from '../assets/images/icon_seguridad_desktop.png';
import icon_contacto_desktop from '../assets/images/icon_contacto_desktop.png';

import '../css/NavBar.css';

const NavBar = () => {
    const [expanded, setExpanded] = useState(false);

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    const closeNavbar = () => {
        setExpanded(false);
    };

    return (
        <Navbar expand="lg" expanded={expanded} className="custom-navbar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} className={expanded ? 'is-open' : ''}>
                <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <div className="button-container">
                        <Link to="/contacto" id="butt_contacto_mob" onClick={closeNavbar}><img src={icon_contacto_desktop} alt='icon'></img></Link>
                        <Link to="/comunicacion-y-conectividad" id="butt_comunicacion_mob" onClick={closeNavbar}><img src={icon_comunicacion_desktop} alt='icon'></img></Link>
                        <Link to="/cloud" id="butt_cloud_mob" onClick={closeNavbar}><img src={icon_cloud_desktop} alt='icon'></img></Link>
                        <Link to="/iot" id="butt_iot_mob" onClick={closeNavbar}><img src={icon_iot_desktop} alt='icon'></img></Link>
                        <Link to="/seguridad" id="butt_seguridad_mob" onClick={closeNavbar}><img src={icon_seguridad_desktop} alt='icon'></img></Link>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
