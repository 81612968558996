import React, { useEffect, useRef } from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosComunicacion from '../../components/BeneficiosComunicacion';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';

import logocomunicacion from '../../assets/images/comunicacion/logocomunicacion.png';

import path_to_icon1 from '../../assets/images/comunicacion/movistarlink/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/comunicacion/movistarlink/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/comunicacion/movistarlink/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/comunicacion/movistarlink/path_to_icon4.png';

import path_to_icon5 from '../../assets/images/comunicacion/movistarlink/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/comunicacion/movistarlink/path_to_icon6.png';
import path_to_icon7 from '../../assets/images/comunicacion/movistarlink/path_to_icon7.png';






const MovistarLink = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    anime({
      targets: buttonRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 2500,
      duration: 1000,
      easing: 'easeOutExpo',
    });
  }, []);

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Tráfico priorizado</b> Servicio empresarial de tráfico de datos con prioridad sobre servicios residenciales.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Mayor rendimiento</b> Cobertura satelital global en órbita LEO con mayor rendimiento vs. servicios satelitales tradicionales.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Óptimo funcionamiento en intemperie</b> Equipo diseñado para funcionar a alto rendimiento incluso a la intemperie y en entornos externos.'
    },
    {
      icon: path_to_icon4,
      text: '<b>Conexión en remoto</b> Permite la conexión en minutos de la empresa incluso en zonas de difícil acceso con aplicativos Starlink.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon5,
      text: '<b>Spare Part</b> Disponibilidad de reparaciones en caso de fallos del equipo.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Atención de requerimientos</b> Atención personalizada con un equipo especializado 24/7.'
    },
    {
      icon: path_to_icon7,
      text: '<b>Facturación</b> Modelo de facturación empresarial con diversidad de medios de pago.'
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logocomunicacion}
        imgAlt="Logo de seguridad digital"
        description="Internet Satelital: una conexión sólida para tu negocio, estés donde estés descubre el internet satelital de mayor cobertura y velocidad."
      />

      <SubHeader
        title="<b>Movistar Link</b>"
        subtitle1="La mejor experiencia de conectividad privada."
        subtitle2="Internet Satelital: una conexión sólida para tu negocio, estés donde estés descubre el internet satelital de mayor cobertura y velocidad"
      />

      <MarqueeText text="Movistar Link" />

      <Link to="/comunicacion-y-conectividad/movistar-link/contacto">
        <button ref={buttonRef} className='conocemasaca'>
          Conocé más acá 
        </button>
      </Link>

      <BeneficiosComunicacion/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />
      
    </div>
  );
};

export default MovistarLink;
