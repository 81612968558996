import React, { useEffect, useState } from 'react';
import Login from './Login'; 

const RegistrosList = () => {
  const [files, setFiles] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isAuthenticated) {
      // Realizando la solicitud a la API
      fetch(`${apiUrl}/registros`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setFiles(data); 
        })
        .catch(error => {
          console.error('Error al recuperar archivos:', error);
        });
    }
  }, [isAuthenticated, apiUrl]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleDelete = (id, source) => {
    fetch(`${apiUrl}/registros/${id}`, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          // Actualiza la lista de registros después de la eliminación
          setFiles(files.filter(file => file._id !== id || file.source !== source));
        } else {
          console.error('Error al eliminar el registro.');
        }
      })
      .catch(error => {
        console.error('Error al realizar la solicitud de eliminación:', error);
      });
  };

  if (!isAuthenticated) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className='registrados'>
      <h1>Consola de correos</h1>
        <ul>
          {files.length === 0 && <p>No se encontraron registros.</p>}
          {files.map(file => (
            <li key={file._id} className={`box-default ${file.source === 'MovistarLink' ? 'movistar-link' : 'movistar'}`}>
              {file.source === 'Movistar' && (
                <>
                  <p><strong>Nombre:</strong> {file.name}</p>
                  <p><strong>Celular:</strong> {file.phone}</p>
                  <p><strong>Correo:</strong> {file.email}</p>
                  <p><strong>RUT:</strong> {file.rut ? file.rut : 'Sin RUT'}</p>
                  <p><strong>Comentarios:</strong> {file.comments ? file.comments : 'Sin comentarios'}</p>
                </>
              )}

              {file.source === 'MovistarLink' && (
                <>
                  <p><strong>Nombre:</strong> {file.name}</p>
                  <p><strong>Celular:</strong> {file.phone}</p>
                  <p><strong>Correo:</strong> {file.email}</p>
                  <p><strong>RUT:</strong> {file.rut ? file.rut : 'Sin RUT'}</p>
                </>
              )}
              <button onClick={() => handleDelete(file._id, file.source)} className="btn">Borrar</button>
              <p><em>Fuente: {file.source}</em></p>
            </li>
          ))}
        </ul>
    </div>
  );
};

export default RegistrosList;
