import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';

const VideoContainer = ({ videoSrc, floatText, bottomText }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    anime({
      targets: videoRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 1000,
      duration: 1000,
      easing: 'easeOutExpo',
    });
  }, []);

  return (
    <>
      <section className='video-section' ref={videoRef}>
        <iframe 
          width="700" 
          height="400" 
          src={videoSrc}
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          title="Video"
        ></iframe>
        <p className='floatvideop'>{floatText}</p>
      </section>
      <p className='bottomvideop' dangerouslySetInnerHTML={{ __html: bottomText }} />
    </>
  );
};

export default VideoContainer;
