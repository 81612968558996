import React from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from './LogoSVG'
import icon_comunicacion_mobile from '../assets/images/icon_comunicacion_mobile.png';
import icon_cloud_mobile from '../assets/images/icon_cloud_mobile.png';
import icon_iot_mobile from '../assets/images/icon_iot_mobile.png';
import icon_seguridad_mobile from '../assets/images/icon_seguridad_mobile.png';
import icon_contacto_mobile from '../assets/images/icon_contacto_mobile.png';
import NavBar from './NavBar'; 



const HeaderMenu = () => {

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <LogoSVG />
        </Link>
      </div>

      <div className='show-mobile'>
        <NavBar/>
      </div>

      <div className='hide-mobile'>
        <nav>
          <ul className="nav-list">
            <div className="button-container">
              <Link to="/contacto" id="butt_contacto_mob"><img src={icon_contacto_mobile} alt='icon'></img></Link>
              <Link to="/comunicacion-y-conectividad" id="butt_comunicacion_mob"><img src={icon_comunicacion_mobile} alt='icon'></img></Link>
              <Link to="/cloud" id="butt_cloud_mob"><img src={icon_cloud_mobile} alt='icon'></img></Link>
              <Link to="/iot" id="butt_iot_mob"><img src={icon_iot_mobile} alt='icon'></img></Link>
              <Link to="/seguridad" id="butt_seguridad_mob"><img src={icon_seguridad_mobile} alt='icon'></img></Link>
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default HeaderMenu;
